/* eslint-disable camelcase */
import React, { Fragment } from 'react';

import AnimatedScrollDown from 'assets/icons/scroll/AnimatedScrollDown';
import CmsModules from 'libs/wordpress/content/CmsModules';
import OrganizationStructuredData from 'components/metadata/structuredData/OrganizationStructuredData';
import PropTypes from 'prop-types';
import SiteSearchStructuredData from 'components/metadata/structuredData/SiteSearchStructuredData';
import appProp from 'utils/proptypes/application';
import { injectModels } from 'state';
import pageProps from 'utils/proptypes/page';

const FrontPage = ({ data, page, application: { path, domain, protocol, config } }) => {
    const { hero_content: heroContent, page_content: pageContent } = data;
    return (
        <Fragment>
            {page.data && (
                <OrganizationStructuredData
                    path={path}
                    domain={domain}
                    protocol={protocol}
                    pageData={page.data}
                    companyData={config.options?.company_data || {}}
                />
            )}
            <SiteSearchStructuredData />
            <AnimatedScrollDown position="absolute" right={['12px', null, null, '32px']} bottom="32px" zIndex={5} />
            {heroContent && <CmsModules isHero data={heroContent[0]} />}
            <CmsModules data={pageContent} />
        </Fragment>
    );
};

FrontPage.propTypes = {
    application: appProp.isRequired,
    data: PropTypes.shape({
        hero_content: PropTypes.array.isRequired,
        page_content: PropTypes.array.isRequired,
    }).isRequired,
    page: pageProps.isRequired,
};

export default injectModels(['page', 'application'])(FrontPage);
